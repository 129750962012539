<template>
  <div ref="businessSettlement" class="businessSettlement">
    <div ref="tabs" class="tabs">
      <el-tabs v-model="tab" type="card" @tab-click="handleClick">
        <el-tab-pane label="统计" name="1">
          <div ref="form" class="form-area">
            <el-form
              :inline="true"
              :model="form"
              class="demo-form-inline"
              label-position="right"
              label-width="85px"
            >
              <el-form-item label="发车时间：" class="code-time-range">
                <el-date-picker
                  v-model="time"
                  range-separator="至"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="所属公司：">
                <companySelect
                  :clearable="true"
                  :company-tree="companyList"
                  :valuename="valuename"
                  :value="value"
                  @getValue="getGroupId"
                ></companySelect>
              </el-form-item>
              <el-form-item label="线路：">
                <el-select
                  v-model="form.lineId"
                  clearable
                  filterable
                  placeholder="请选择线路"
                >
                  <el-option
                    v-for="role in lineList"
                    :key="role.id"
                    :label="role.name"
                    :value="role.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="司机：">
                <el-select
                  v-model="form.driverId"
                  filterable
                  remote
                  reserve-keyword
                  placeholder="请输入司机"
                  clearable
                  :remote-method="queryDriver"
                  @clear="form.driverId = null"
                >
                  <el-option
                    v-for="item in driverList"
                    :key="item.id"
                    :label="item.userName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="车牌号：">
                <el-select
                  v-model="form.vehicleId"
                  filterable
                  remote
                  reserve-keyword
                  clearable
                  placeholder="请输入车牌号"
                  :remote-method="queryCph"
                  @clear="form.vehicleId = null"
                >
                  <el-option
                    v-for="item in cphList"
                    :key="item.id"
                    :label="item.licensePlateNumber"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="queryFun1"
                  >查询</el-button
                >
                <el-button
                  v-if="listFind('导出')"
                  type="primary"
                  size="small"
                  @click="tableExport1"
                  >导出</el-button
                >
              </el-form-item>
            </el-form>
          </div>
          <div class="box">
            <div class="item">
              <span>{{ dataObj.totalOrderCount || 0 }}</span>
              <p>电召订票数</p>
            </div>
            <div class="item">
              <span>{{ dataObj.onlinePayCount || 0 }}</span>
              <p>线上支付订单数</p>
            </div>
            <div class="item">
              <span>{{ dataObj.onlinePayAmount || 0 }}</span>
              <p>线上支付金额</p>
            </div>
            <div class="item">
              <span>{{ dataObj.driverPayCount || 0 }}</span>
              <p>司机补付订单数</p>
            </div>
            <div class="item">
              <span>{{ dataObj.driverPayAmount || 0 }}</span>
              <p>司机补付金额</p>
            </div>
            <div class="item">
              <span>{{ dataObj.driverCashCount || 0 }}</span>
              <p>收现订单数</p>
            </div>
            <div class="item">
              <span>{{ dataObj.driverCashAmount || 0 }}</span>
              <p>收现金额</p>
            </div>
          </div>
          <Table :table-data="tableData1" :title-name="titleName1"> </Table>
          <div class="pagination">
            <el-pagination
              background
              layout="total,sizes,prev, pager, next,jumper"
              :total="total"
              :page-size="form.pageSize"
              :current-page="form.currentPage"
              @size-change="onSizeChange"
              @current-change="onCurrentChange"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="司机补付明细" name="2">
          <div ref="area" class="form-area">
            <el-form
              :inline="true"
              :model="form"
              class="demo-form-inline"
              label-position="right"
              label-width="85px"
            >
              <el-form-item label="发车时间：" class="code-time-range">
                <el-date-picker
                  v-model="time"
                  range-separator="至"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="所属公司：">
                <companySelect
                  :clearable="true"
                  :company-tree="companyList"
                  :valuename="valuename"
                  :value="value"
                  @getValue="getGroupId"
                ></companySelect>
              </el-form-item>
              <el-form-item label="线路：">
                <el-select
                  v-model="form.lineId"
                  clearable
                  filterable
                  placeholder="请选择线路"
                >
                  <el-option
                    v-for="role in lineList"
                    :key="role.id"
                    :label="role.name"
                    :value="role.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="司机：">
                <el-select
                  v-model="form.driverId"
                  filterable
                  remote
                  reserve-keyword
                  placeholder="请输入司机"
                  clearable
                  :remote-method="queryDriver"
                  @clear="form.driverId = null"
                >
                  <el-option
                    v-for="item in driverList"
                    :key="item.id"
                    :label="item.userName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="车牌号：">
                <el-select
                  v-model="form.vehicleId"
                  filterable
                  remote
                  reserve-keyword
                  clearable
                  placeholder="请输入车牌号"
                  :remote-method="queryCph"
                  @clear="form.vehicleId = null"
                >
                  <el-option
                    v-for="item in cphList"
                    :key="item.id"
                    :label="item.licensePlateNumber"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="queryFun2"
                  >查询</el-button
                >
                <el-button
                  v-if="listFind('导出2')"
                  type="primary"
                  size="small"
                  @click="tableExport2"
                  >导出</el-button
                >
              </el-form-item>
            </el-form>
          </div>
          <div class="table">
            <Table
              :table-data="tableData2"
              :title-name="titleName2"
              :operation="true"
            >
              <template slot-scope="scope">
                <el-button
                  v-if="listFind('查看')"
                  type="text"
                  @click="referFun(scope.scopeRow)"
                  >查看</el-button
                >
              </template>
            </Table>
          </div>
          <div ref="pagination" class="pagination">
            <el-pagination
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              :page-size="form.pageSize"
              :current-page="form.currentPage"
              @size-change="onSizeChange"
              @current-change="onCurrentChange"
            ></el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog title="订单详情" width="1200px" :visible.sync="show">
      <div class="info">
        <div class="item">订单编号：{{ dataObj.driverOrderId }}</div>
        <div style="width: 250px">线路：{{ dataObj.lineName }}</div>
        <div class="item">发车时间：{{ dataObj.departureDateTime }}</div>
        <div class="item">支付时间：{{ dataObj.wxTransactionTime }}</div>
      </div>
      <div class="info">
        <div class="item">司机姓名：{{ dataObj.driverName }}</div>
        <div style="width: 250px">车牌号：{{ dataObj.licensePlateNumber }}</div>
        <div class="item">补付订单数：{{ dataObj.paymentCount }}</div>
        <div class="item">补付金额：{{ dataObj.totalPrices }}</div>
      </div>
      <h3>乘客订单</h3>
      <Table
        :table-data="tableData3"
        :title-name="titleName3"
        :table-height="250"
      >
      </Table>
    </el-dialog>
  </div>
</template>

<script>
import {
  queryDriverCashRecordPage,
  getCompanyTree,
  queryStatisticsPage,
  exportStatistics,
  exportDriverCashRecord,
  getVehicleList,
  getDriverList,
  getAllLine,
} from "@/api/lib/api.js";
import { lastTime } from "@/tools/getTime.js";
import moment from "moment";
import companySelect from "@/components/treeSelect/companySelect.vue";
export default {
  components: {
    companySelect,
  },
  data() {
    return {
      tab: "1",
      form: {
        currentPage: 1,
        pageSize: 10,
        departureDateTimeBegin: null,
        departureDateTimeEnd: null,
        companyId: null,
        vehicleId: null,
        lineId: null,
        diverId: null,
      },
      time: null,
      exportForm: {},
      value: null,
      valuename: null,
      companyList: [],
      tableData1: [],
      titleName1: [
        {
          title: " 发车时间",
          props: "departureDateTime",
        },
        {
          title: "公司",
          props: "companyName",
        },
        {
          title: "线路",
          props: "lineName",
        },
        {
          title: "司机姓名",
          props: "driverName",
        },
        {
          title: "车牌号",
          props: "licensePlateNumber",
        },
        {
          title: "电召订票数",
          props: "totalCount",
        },
        {
          title: "线上支付订单数",
          props: "onlinePaymentCount",
        },
        {
          title: "线上支付金额",
          props: "onlineTotalPrices",
        },
        {
          title: "司机补付订单数",
          props: "driverPayCount",
        },
        {
          title: "司机补付金额",
          props: "driverPayAmount",
        },
        {
          title: "收现订单数",
          props: "driverCashCount",
        },
        {
          title: "收现金额",
          props: "driverCashAmount",
        },
      ],
      total: 0,
      driverList: [],
      cphList: [],
      lineList: [],
      tableData2: [],
      titleName2: [
        {
          title: "订单编号",
          props: "driverOrderId",
        },
        {
          title: "线路",
          props: "lineName",
        },
        {
          title: "发车时间",
          props: "departureDateTime",
        },
        {
          title: "司机姓名",
          props: "driverName",
        },
        {
          title: "车牌号",
          props: "licensePlateNumber",
        },
        {
          title: "补付订单数",
          props: "paymentCount",
        },
        {
          title: "补付金额",
          props: "totalPrices",
        },
        {
          title: "支付时间",
          props: "wxTransactionTime",
        },
      ],
      dataObj: {},
      titleName3: [
        {
          title: "订单编号",
          props: "orderId",
        },
        {
          title: "票号",
          props: "id",
        },
        {
          title: "票价",
          props: "ticketPricesPer",
        },
        {
          title: "服务费",
          props: "servicePricesPer",
        },
        {
          title: "应付金额",
          props: "payablePrices",
        },
        {
          title: "实付金额",
          props: "totalPrices",
        },
        {
          title: "下单人",
          props: "passengerName",
        },
        {
          title: "联系人",
          props: "passengerPhone",
        },
      ],
      tableData3: [],
      show: false,
      dataObj: {},
    };
  },
  mounted() {
    const date = lastTime(new Date());
    const day = moment().format("YYYY-MM-DD");
    this.time = [date, day];
    this.getCompanyList();
    this.getLineList();
    this.renderData1();
  },
  methods: {
    handleClick() {
      this.value = null;
      this.valuename = null;
      const date = lastTime(new Date());
      const day = moment().format("YYYY-MM-DD");
      this.time = [date, day];
      this.form = {
        currentPage: 1,
        pageSize: 10,
        departureDateTimeBegin: null,
        departureDateTimeEnd: null,
        companyId: null,
        vehicleId: null,
        lineId: null,
        diverId: null,
      };
      this.cphList = [];
      if (this.tab == "1") {
        this.renderData1();
      }
      if (this.tab == "2") {
        this.renderData2();
      }
    },
    //获取所属公司
    getCompanyList() {
      getCompanyTree({ parentId: sessionStorage.getItem("companyId") }).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.companyList = res.data;
          }
        }
      );
    },
    getLineList() {
      getAllLine().then((res) => {
        if (res.code === "SUCCESS") {
          this.lineList = res.data;
        }
      });
    },
    queryCph(query) {
      this.cphList = [];
      if (query !== "") {
        setTimeout(() => {
          getVehicleList({
            licensePlateNumber: query,
            currentPage: 1,
            pageSize: 20,
          }).then((res) => {
            if (res.code === "SUCCESS") {
              this.cphList = res.data.list;
            }
          });
        }, 200);
      } else {
        this.cphList = [];
      }
    },
    queryDriver(query) {
      this.driverList = [];
      if (query !== "") {
        setTimeout(() => {
          const data = {
            currentPage: 1,
            pageSize: 10,
            userName: query,
          };
          getDriverList(data).then((res) => {
            if (res.code === "SUCCESS") {
              this.driverList = res.data.list;
            }
          });
        }, 200);
      } else {
        this.driverList = [];
      }
    },
    queryFun1() {
      this.form.currentPage = 1;
      this.form.pageSize = 10;
      this.renderData1();
    },
    // 表格数据渲染
    renderData1() {
      if (this.time) {
        this.form.departureDateTimeBegin = this.time[0] + " 00:00:00";
        this.form.departureDateTimeEnd = this.time[1] + " 23:59:59";
      } else {
        this.form.departureDateTimeBegin = null;
        this.form.departureDateTimeEnd = null;
      }
      queryStatisticsPage(this.form).then((res) => {
        this.tableData1 = res.data.pageList.list;
        this.total = res.data.pageList.total;
        this.dataObj.onlinePayCount = res.data.onlinePayCount;
        this.dataObj.onlinePayAmount = res.data.onlinePayAmount;
        this.dataObj.driverPayCount = res.data.driverPayCount;
        this.dataObj.driverPayAmount = res.data.driverPayAmount;
        this.dataObj.driverCashCount = res.data.driverCashCount;
        this.dataObj.driverCashAmount = res.data.driverCashAmount;
        this.dataObj.totalOrderCount = res.data.totalOrderCount;
      });
    },
    // 点击导出按钮
    tableExport1() {
      let obj = this.deepClone(this.form);
      delete obj.currentPage;
      delete obj.pageSize;
      exportStatistics(obj).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName = "电召订单 " + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");
        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击
        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
    getGroupId(v) {
      if (v == null) {
        this.form.companyId = null;
      } else {
        this.form.companyId = v;
      }
    },
    // 表格详情
    referFun(row) {
      this.show = true;
      this.dataObj = row;
      this.tableData3 = row.intercityChildOrders;
    },
    onCurrentChange(page) {
      this.form.currentPage = page;
      this.tab == "1" && this.renderData1();
      this.tab == "2" && this.renderData2();
    },
    onSizeChange(size) {
      this.form.pageSize = size;
      this.form.currentPage = 1;
      this.tab == "1" && this.renderData1();
      this.tab == "2" && this.renderData2();
    },
    queryFun2() {
      this.form.currentPage = 1;
      this.form.pageSize = 10;
      this.renderData2();
    },
    // 表格数据渲染2
    renderData2() {
      if (this.time) {
        this.form.departureDateTimeBegin = this.time[0] + " 00:00:00";
        this.form.departureDateTimeEnd = this.time[1] + " 23:59:59";
      } else {
        this.form.departureDateTimeBegin = null;
        this.form.departureDateTimeEnd = null;
      }
      queryDriverCashRecordPage(this.form).then((res) => {
        this.tableData2 = res.data.list;
        this.total = res.data.total;
      });
    },
    // 点击导出按钮
    tableExport2() {
      let obj = this.deepClone(this.form);
      delete obj.currentPage;
      delete obj.pageSize;
      exportDriverCashRecord(obj).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName = "司机补票明细 " + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");
        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击
        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.businessSettlement {
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
  background: #ffffff;
  height: 100%;
  display: flex;
  flex-direction: column;
  /deep/.el-range-separator {
    line-height: 26px !important;
  }
  /deep/.el-date-editor.el-input__inner {
    width: 13vw !important;
  }
  .box {
    margin-top: 16px;
    display: flex;
    .item {
      margin: 20px 40px;
      span {
        font-size: 16px;
        font-weight: bold;
      }
      p {
        margin-top: 10px;
      }
    }
  }
  .table {
    margin-top: 10px;
  }
  .info {
    display: flex;
    font-size: 15px;
    margin: 30px 0;
    .item {
      flex: 1;
    }
  }
}
</style>
